import React, { useState, useEffect } from "react";
import { get_products_for_category } from "../api/endpoint";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ProductItem from "../components/ProductItem";
import { image_url } from "../environment";

const Category = ({ match }) => {
  const [products, setProducts] = useState([]);
  const [categoryInfo, setCategoryInfo] = useState({});
  const category_id = match.params.id;

  useEffect(() => {
    async function get() {
      window.scrollTo(0, 0);
      const localCategories = JSON.parse(localStorage.getItem("categories"));
      const category = localCategories.find(
        (value) => value.id === category_id
      );
      setCategoryInfo(category);
      console.log(category);
      const data = await get_products_for_category(category.category);
      setProducts(data);
    }
    get();
  }, [category_id]);

  return (
    <div>
      <Header />
      <section className="container">
        <div className="px-4 py-1 my-5 text-center">
          <img
            className="d-block mx-auto mb-4"
            src={`../imgs/${categoryInfo.images}`}
            alt=""
            width="57"
            height="57"
          />
          <h1 className="fw-bold">{categoryInfo.category}</h1>
          <div className="col-lg-9 mx-auto">
            <p className="lead mb-4">
              {categoryInfo.subcategories &&
                categoryInfo.subcategories.map((cate) => {
                  return (
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-sm"
                      style={{ marginLeft: 10, marginBottom: 10 }}
                    >
                      {cate}
                    </button>
                  );
                })}
            </p>
          </div>
        </div>
        <div className="row" style={{ padding: 0, marginTop: 0 }}>
          <div className="col-md-12">
            <div
              className="row categoryPage"
              style={{ padding: 0, marginTop: 0 }}
            >
              {products.map((product) => (
                <div className="col-md-4" key={product.product_id}>
                  <ProductItem product={product} />
                </div>
              ))}
            </div>
            {products.length === 0 && (
              <div className="px-4 py-5 my-5 text-center">
                <img
                  className="d-block mx-auto mb-4"
                  src="/../logo.png"
                  alt=""
                  width="72"
                  height="57"
                />
                <h3>No products Added</h3>
                <div className="col-lg-6 mx-auto">
                  <p className="lead mb-4"></p>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Category;
