import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { get_a_store, incrementViewOnStore } from "../api/endpoint";
import ProductItem from "../components/ProductItem";
import { image_url } from "../environment";

const Store = ({ match }) => {
  const [store, setStore] = useState(null);
  const store_id = match.params.id;

  useEffect(() => {
    incrementViewOnStore(store_id);
    async function get() {
      window.scrollTo(0, 0);
      const data = await get_a_store(store_id);
      console.log(data);
      setStore(data);
    }
    get();
  }, [store_id]);
  return (
    <>
      <Header />
      <div className="container">
        {store && (
          <div className="row mt-10" style={{ padding: 0 }}>
            <div className="col-md-3">
              <div className="card" style={{ width: "100%" }}>
                {store.store.cover ? (
                  <img
                    src={`${image_url}/${store.store.cover}`}
                    className="card-img-top"
                    alt="..."
                  />
                ) : (
                  <div
                    style={{ height: 150, backgroundColor: "#f1f1f1" }}
                  ></div>
                )}

                <div className="card-body">
                  <h5 className="card-title">{store.store.store_name}</h5>
                  <p className="card-text">
                    <small>{store.store.category}</small>
                  </p>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <small>
                      Hours open : <b>{store.store.hours_open}</b>
                    </small>
                  </li>
                  <li className="list-group-item">
                    <small>
                      Address: <b>{store.store.address_line}</b>
                    </small>
                  </li>
                  <li className="list-group-item">
                    <small>
                      Joined: <b>{store.store.date_joined}</b>
                    </small>
                  </li>
                </ul>
              </div>
              <div style={{ textAlign: "center" }}>
                <hr />
                <h5>Contact Store</h5>
                <hr />
                <img
                  src={`${image_url}/${store.store.logo}`}
                  className="card-img-top dp"
                  alt="..."
                />
                <h5>{store.user.name}</h5>
                <small>{store.user.phone}</small>
                <br />
                <small>{store.user.email}</small>
                <br />
                <div style={{ height: 200 }} />
              </div>
            </div>
            <div className="col-md-9" style={{ background: "#f1f1f1" }}>
              <div className="row" style={{ padding: 0 }}>
                {store.products.map((product) => (
                  <div className="col-md-4" key={product.product_id}>
                    <ProductItem product={product} />
                  </div>
                ))}
                {store.products.length === 0 && (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h4>No Products Added by store yet</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Store;

// cover: ""
// created_at: "0"
// : "0"
// district: "Kampala"

// lat: ""
// lon: ""
