import React, { useEffect, useState } from "react";
import { get_all_stores } from "../api/endpoint";
import Footer from "../components/Footer";
import Header from "../components/Header";
import StoreItem from "../components/StoreItem";

const Stores = () => {
  const [stores, setStores] = useState([]);

  useEffect(() => {
    async function get() {
      window.scrollTo(0, 0);
      const data = await get_all_stores();
      setStores(data);
    }
    get();
  }, []);
  return (
    <div>
      <Header />
      <section className="text-center container">
        <div className="row" style={{ padding: 0 }}>
          <div className="col-lg-6 col-md-8 mx-auto">
            <h1 class="display-5 fw-bold">Major Stores</h1>
            <p className="lead text-muted">
              All major store a on one platform, Shop online for shoes,
              clothing, jewelry, dresses, makeup and more from top brands. Make
              returns in store or by mail.
            </p>
            <p>
              <a
                href="https://www.upricer_dashboard.ultiro.se/dashboard/login?signup"
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary my-3"
                style={{ marginRight: 20 }}
              >
                Add Store
              </a>
            </p>
          </div>
        </div>
      </section>

      <div className="album">
        <div className="container">
          <div class="row mb-2" style={{ padding: 0 }}>
            {stores.slice(0, 4).map((store) => (
              <StoreItem
                store={store}
                size={"col-md-4"}
                key={store.store.store_id}
              />
            ))}
          </div>
        </div>
      </div>
      <div style={{ height: 100 }} />
      <Footer />
    </div>
  );
};

export default Stores;
