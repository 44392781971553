import axios from "./axios";
export const url = process.env.IMAGE_URL;

export const login = async (user) => {
  const result = await axios.post(`/login/api`, user);
  return result.data;
};

export const register = async (user) => {
  const result = await axios.post(`/register_user/api`, user);
  return result.data;
};

export const edit = async (user, user_id) => {
  const result = await axios.post(`/edit_profile/${user_id}`, user);
  return result.data;
};

//getUserData

export const getUserData = async (user_id) => {
  const result = await axios.get(`/getUserData/${user_id}`);
  return result.data;
};

export const get_products = async () => {
  const result = await axios.get(`/get_products`);
  return result.data;
};

export const get_products_for_category = async (category_id) => {
  const result = await axios.get(`/get_products_for_category/${category_id}`);
  return result.data;
};

export const get_product = async (product_id) => {
  const result = await axios.get(`/get_a_product/${product_id}`);
  return result.data;
};

export const get_categories = async () => {
  const result = await axios.get(`/getCategoriesFromCSV`);
  return result.data;
};

export const get_all_deals = async () => {
  const result = await axios.get(`/getDeals`);
  return result.data;
};

export const post_review = async (review) => {
  const result = await axios.post(`/post_review`, review);
  return result.data;
};

export const get_all_stores = async () => {
  const result = await axios.get(`/stores`);
  return result.data;
};

export const get_a_store = async (store_id) => {
  const result = await axios.get(`/getStore/${store_id}`);
  return result.data;
};

export const add_to_wishList = async (wish) => {
  const result = await axios.post(`/add_to_wishList`, wish);
  return result.data;
};

export const add_to_alert_price = async (price) => {
  const result = await axios.post(`/add_to_alert_price`, price);
  return result.data;
};

export const get_store_products = async (store_id) => {
  const result = await axios.get(`/get_products_for_store/${store_id}`);
  return result.data;
};

export const addReview = async (data) => {
  const result = await axios.post(`/post_review`, data);
  return result.data;
};

export const get_products_for_sub_category = async (sub_category_id) => {
  const result = await axios.get(
    `/get_products_for_sub_category/${sub_category_id}`
  );
  return result.data;
};

export const get_compare_data = async (product_id) => {
  const result = await axios.get(`/get_compare_data/${product_id}`);
  return result.data;
};

export const get_products_for_Search = async (query) => {
  const result = await axios.get(`/get_products_for_Search/${query}`);
  return result.data;
};

export const sendViewForProduct = async (product_id) => {
  const result = await axios.get(`/incrementForProduct/${product_id}`);
  return result.data;
};

export const incrementViewOnStore = async (store_id) => {
  const result = await axios.get(`/incrementViewOnStore/${store_id}`);
  return result.data;
};
