import React, { useEffect, useState } from "react";
import {
  addReview,
  add_to_alert_price,
  add_to_wishList,
  get_product,
  get_products_for_category,
  post_review,
  sendViewForProduct,
} from "../api/endpoint";
import Footer from "../components/Footer";
import Header from "../components/Header";
import StoreListItem from "../components/StoreListItem";
import { image_url } from "../environment";
import Row from "../components/Row";

let percentage = 0;

const Product = ({ match, location, history }) => {
  const [product, setProduct] = useState(null);
  const [email, setEmail] = useState("");
  const [user, setUser] = useState(null);
  const [rating, setRating] = useState(0);
  const [featuredProducts, setFeatured] = useState([]);
  const [reviewText, setReviewText] = useState("");
  const product_id = match.params.id;
  useEffect(() => {
    sendView();
    async function get() {
      window.scrollTo(0, 0);
      const prdt = await get_product(product_id);

      percentage = Math.floor(
        ((parseInt(prdt.product.discount_price, 10) -
          parseInt(prdt.product.price, 10)) *
          100) /
          parseInt(prdt.product.price, 10)
      );

      const data = localStorage.getItem("u_pricer_user");
      const local = data !== null ? JSON.parse(data) : null;
      if (local) {
        setUser(local);
      }
      setProduct(prdt);
      getFeaturedProducts(prdt.category_id);
    }
    get();
  }, [product_id]);

  const getFeaturedProducts = async (category_id) => {
    const featured = await get_products_for_category(category_id);
    setFeatured(featured);
  };

  const sendView = async () => {
    await sendViewForProduct(product_id);
  };

  const addToWishList = async () => {
    if (user) {
      const data = {
        product_id: +product_id,
        user_id: user.user_id,
      };
      await add_to_wishList(data);
      alert("Added to wishList");
    } else {
      history.push(`/login`);
    }
  };

  const addToAlertPrice = async () => {
    if (user) {
      const data = {
        product_id: +product_id,
        user_id: user.user_id,
      };
      await add_to_alert_price(data);
      alert("Added to alerts");
    } else {
      history.push(`/login`);
    }
  };

  const post_review = () => {
    if (user) {
      const data = {
        product_id: product_id,
        user_id: user?.user_id,
        review: reviewText,
        rating,
      };
      addReview(data)
        .then((res) => console.log(res))
        .catch((error) => console.log(error));
      alert("Review Added");
    } else {
      history.push(`/Auth`);
    }
  };

  const compare = () => {
    history.push(`/compare/${product.product.product_id}`);
  };

  const renderFeatures = (value) => {
    const keys = Object.keys(value)[0];
    return (
      <>
        <small className="featureItem">
          {keys} : {value[keys]}
        </small>
        <br />
      </>
    );
  };

  return (
    <div>
      <Header />
      <section>
        {product && (
          <div className="container">
            <div className="row productLayout" style={{ padding: 0 }}>
              <div className="col-md-5 text-center">
                <img
                  src={`${image_url}/${product.product.images}`}
                  alt=""
                  style={{ marginBottom: 40, width: "70%", height: "auto" }}
                />
              </div>
              <div className="col-md-7">
                <h2
                  className="display-5"
                  style={{ textTransform: "capitalize" }}
                >
                  {product.product.prdt_name}
                </h2>
                {product.product.discount_price !== "0" && (
                  <p>
                    <b style={{ color: "red" }}>
                      With a discount of {`${percentage}%`} Off
                    </b>
                  </p>
                )}

                <h5>
                  <small
                    style={{
                      textDecoration:
                        product.product.discount_price !== "0"
                          ? "line-through"
                          : "",
                    }}
                  >
                    Ush {parseInt(product.product.price).toLocaleString("en")}
                  </small>
                  {product.product.discount_price !== "0" && (
                    <>
                      <small style={{ color: "red", marginLeft: 15 }}>
                        Ush{" "}
                        {parseInt(
                          product.product.discount_price
                        ).toLocaleString("en")}
                      </small>
                    </>
                  )}
                </h5>
                <p style={{ color: "#20c997" }}>
                  Available in {product.stores.length} stores
                </p>
                <p>
                  {product.product.description.length > 100
                    ? product.product.description.slice(0, 100)
                    : product.product.description}
                  ...
                </p>
                <hr />
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  style={{ marginRight: 10 }}
                  onClick={() => addToWishList()}
                >
                  + WishList
                </button>

                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() => addToAlertPrice()}
                  style={{ marginRight: 10 }}
                >
                  + Price Alert
                </button>
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => compare()}
                >
                  Compare
                </button>
              </div>
            </div>
            <nav className="nav">
              <a className="nav-link active" aria-current="page" href="#stores">
                Stores
              </a>
              <a className="nav-link" href="#details">
                Product Details
              </a>
              <a className="nav-link" href="#comments">
                Comments
              </a>
              <a className="nav-link" href="#related">
                Related products
              </a>
            </nav>

            <div id="stores" className="prdt_details">
              <div className="my-3 p-3 bg-body rounded ">
                <h6 className=" pb-2 mb-0">All Stores</h6>
                <div className="table-responsive">
                  {product.stores.map((store) => (
                    <StoreListItem store={store} key={store.store_id} />
                  ))}
                </div>
              </div>
            </div>
            <div id="details" className="prdt_details">
              <div className="my-3 p-3 bg-body rounded ">
                <h6 className=" pb-2 mb-0">Details</h6>
                <div className="row" style={{ padding: 0 }}>
                  <div className="col-md-6">
                    <h2 className="display-5">{product.product.prdt_name}</h2>
                    <h5>
                      <b>
                        Ush{" "}
                        {parseInt(product.product.price).toLocaleString("en")}
                      </b>
                    </h5>
                    <div className="infoData">
                      <p>Available stock {product.product.stock_count}</p>

                      <p>Category {product.product.category_id}</p>

                      <p>Sub Category {product.product.sub_category_id}</p>

                      <p>Tags {product.product.tags}</p>

                      <p>Tags {product.product.tags}</p>

                      <p>Views {product.product.views}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <b>Description</b>
                    <br />

                    <small>{product.product.description}</small>
                    <br />
                    <br />

                    <b>Extra features</b>
                    <br />
                    {product.product.features.map((value) =>
                      renderFeatures(value)
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div id="comments" className="prdt_details">
              <div className="row" style={{ margin: 0, padding: 0 }}>
                <div className="col-md-7">
                  <div className="my-3 p-3 bg-body rounded ">
                    <h6 className=" pb-2 mb-0">Comments and Reviews</h6>
                    {product.reviews.length === 0 && (
                      <div style={{ marginTop: 10 }}>
                        <p>No Reviews</p>
                      </div>
                    )}
                    {product.reviews.map((review, index) => (
                      <div className="d-flex text-muted pt-3" key={index}>
                        <svg
                          className="bd-placeholder-img flex-shrink-0 me-2 rounded"
                          width="32"
                          height="32"
                          xmlns="http://www.w3.org/2000/svg"
                          role="img"
                          aria-label="Placeholder: 32x32"
                          preserveAspectRatio="xMidYMid slice"
                          focusable="false"
                        >
                          <title>Placeholder</title>
                          <rect width="100%" height="100%" fill="#e83e8c" />
                          <text x="50%" y="50%" fill="#e83e8c" dy=".3em">
                            32x32
                          </text>
                        </svg>

                        <p className="pb-3 mb-0 small lh-sm ">
                          <strong className="d-block text-gray-dark">
                            Got a rating of {review.rating}
                          </strong>
                          {review.review}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="col-md-5"
                  style={{ borderLeftWidth: 1, borderLeftColor: "#444" }}
                >
                  <h6 className=" pb-2 mb-0">Post comments and reviews</h6>
                  <hr />
                  <div className="mb-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      disabled={user?.email}
                      value={user?.email}
                      onChange={(text) => setEmail(text.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Rating
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Add rating"
                      max="5"
                      min="1"
                      onChange={(text) => setRating(text.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      for="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      Comments
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      onChange={(text) => setReviewText(text.target.value)}
                      rows="3"
                    ></textarea>
                    <br />
                    <button
                      onClick={() => post_review()}
                      className="btn btn-primary"
                    >
                      Post
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <div id="related" className="prdt_details">
        <div className="my-3 p-3 bg-body rounded ">
          <Row
            title="Featured products"
            description=""
            bgColor="#eee"
            what="most"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Product;
