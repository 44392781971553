import React from "react";
import "./components.scss";

const Footer = () => {
  return (
    <div className="footer">
      <footer className="container py-5">
        <div className="row">
          <div className="col-12 col-md">
            <img src="../logo.png" style={{ height: 70, width: 70 }} />
            <br />
            <br />
            <b>UPRICER</b>
            <small className="d-block mb-3 text-muted">
              &copy; 2021–Present
            </small>
            <p>
              Search and Compare over 1 million products from Over 3000
              retailers
            </p>
          </div>
          <div className="col-6 col-md">
            <h5>Features</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="link-secondary" href="#">
                  Add Store
                </a>
              </li>
              <li>
                <a className="link-secondary" href="#">
                  Advertise wit us
                </a>
              </li>
              <li>
                <a className="link-secondary" href="#"></a>
              </li>
              <li>
                <a className="link-secondary" href="#">
                  Stuff for developers
                </a>
              </li>
              <li>
                <a className="link-secondary" href="#">
                  Categories Ato Z
                </a>
              </li>
              <li>
                <a className="link-secondary" href="#">
                  Why Upricer
                </a>
              </li>
            </ul>
          </div>

          <div className="col-6 col-md">
            <h5>Resources</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="link-secondary" href="#">
                  Shope safely online
                </a>
              </li>
              <li>
                <a className="link-secondary" href="#">
                  Frequently asked questions
                </a>
              </li>
              <li>
                <a className="link-secondary" href="#">
                  Terms and conditions
                </a>
              </li>
              <li>
                <a className="link-secondary" href="#">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>About</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="link-secondary" href="#">
                  Team
                </a>
              </li>
              <li>
                <a className="link-secondary" href="#">
                  Locations
                </a>
              </li>
              <li>
                <a className="link-secondary" href="#">
                  Privacy
                </a>
              </li>
              <li>
                <a className="link-secondary" href="#">
                  Terms
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
