import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { edit, getUserData } from "../api/endpoint";
import ProductItem from "../components/ProductItem";

const Profile = () => {
  const [user, setUser] = useState(null);
  const [moreUser, setMoreUser] = useState({ alerts: [], wishlist: [] });
  const [activeTab, setActiveTab] = useState("profile");

  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [street, setStreet] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const getUser = () => {
    const data = localStorage.getItem("u_pricer_user");
    const local = data !== null ? JSON.parse(data) : null;
    setUser(local);
    getAllUseDetails(local.user_id);
  };

  const getAllUseDetails = async (user_id) => {
    const userInfo = await getUserData(user_id);
    setMoreUser(userInfo);
  };

  const editProfile = () => {
    setLoading(true);
    const data = {
      name: `${firstName} ${lastName}`,
      city,
      country,
      street,
      phone,
    };
    setLoading(true);
    edit(data, user.user_id)
      .then((res) => {
        setLoading(false);

        if (res.flag) {
          setUser(res.user);
        } else {
          setErrorMessage(res.msg);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div>
      <Header />
      <div className="container">
        <br />
        <h2>Hello {user && `${user.name}`},</h2>
        <br />
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "profile" ? "active" : ""}`}
              aria-current="page"
              href="#"
              onClick={() => setActiveTab("profile")}
            >
              My Profile
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "wishlist" ? "active" : ""}`}
              href="#"
              onClick={() => setActiveTab("wishlist")}
            >
              My WishList
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "price" ? "active" : ""}`}
              href="#"
              onClick={() => setActiveTab("price")}
            >
              My Price Alerts
            </a>
          </li>
        </ul>
      </div>

      <section className="detailsSection container">
        {activeTab === "profile" && (
          <div className="profileCard">
            <div className="row" style={{ padding: 0 }}>
              <div className="col-md-6">
                <p style={{ color: "red" }}>{errorMessage && errorMessage}</p>
                <form className="row g-3" style={{ padding: 0 }}>
                  <div className="col-md-4">
                    <label for="inputEmail4" className="form-label">
                      First name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputEmail4"
                      onChange={(text) => setFirstName(text.target.value)}
                      value={firstName}
                    />
                  </div>
                  <div className="col-md-4">
                    <label for="inputPassword4" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      onChange={(text) => setLastName(text.target.value)}
                      value={lastName}
                    />
                  </div>
                  <div className="col-md-4">
                    <label for="inputPassword4" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="inputPassword4"
                      onChange={(text) => setEmail(text.target.value)}
                      value={email}
                    />
                  </div>
                  <div className="col-12">
                    <label for="inputAddress" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputAddress"
                      placeholder="1234 Main St"
                      onChange={(text) => setPhone(text.target.value)}
                      value={phone}
                    />
                  </div>
                  <div className="col-12">
                    <label for="inputAddress2" className="form-label">
                      Street
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputAddress2"
                      placeholder="Apartment, studio, or floor"
                      onChange={(text) => setStreet(text.target.value)}
                      value={street}
                    />
                  </div>
                  <div className="col-md-6">
                    <label for="inputCity" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputCity"
                      onChange={(text) => setCity(text.target.value)}
                      value={city}
                    />
                  </div>

                  <div className="col-md-6">
                    <label for="inputZip" className="form-label">
                      Country
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputZip"
                      onChange={(text) => setCountry(text.target.value)}
                      value={country}
                    />
                  </div>
                </form>
              </div>
              <div
                className="col-md-6"
                style={{ backgroundColor: "#f1f1f1", padding: 10 }}
              >
                <div className="settingsSection">
                  <h6>
                    <b>Notifications and customization</b>
                  </h6>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      Newsletter by email
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      Notifications by email
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      Offers via SMS
                    </label>
                  </div>
                </div>

                <div className="settingsSection">
                  <h6>
                    <b>Price Alert</b>
                  </h6>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      Notifications via SMS
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      Notifications via email
                    </label>
                  </div>
                </div>

                <div className="settingsSection">
                  <h6>
                    <b>Do you want remove your Account</b>
                  </h6>
                  <p>
                    We are sad ro see you go . You are always welcome back if
                    you change your ming
                  </p>
                  <hr />
                  <div className="row" style={{ padding: 0 }}>
                    <div className="col-md-4 col-xs-6">
                      <button
                        className="btn btn-primary"
                        onClick={() => editProfile()}
                        disabled={loading}
                        style={{ marginBottom: 10 }}
                      >
                        Update Account
                      </button>
                    </div>
                    <div className="col-md-4 col-xs-6" />
                    <div className="col-md-4 col-xs-6">
                      <button className="btn btn-outline-danger">
                        Delete Account
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === "price" && (
          <div className="priceCard">
            <div className="row">
              {moreUser.alerts?.map((product) => (
                <div className="col-md-3">
                  <ProductItem product={product} key={product.product_id} />
                </div>
              ))}
              {moreUser.alerts.length === 0 && <div></div>}
            </div>
          </div>
        )}

        {activeTab === "wishlist" && (
          <div className="wishlistCard">
            <div className="row">
              {moreUser.wishlist?.map((product) => (
                <div className="col-md-3">
                  <ProductItem product={product} key={product.product_id} />
                </div>
              ))}
              {moreUser.wishlist.length === 0 && <div></div>}
            </div>
          </div>
        )}
      </section>
      <Footer />
    </div>
  );
};

export default Profile;
