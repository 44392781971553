import React, { useEffect } from "react";
import Banner from "../components/Banner";
import Header from "../components/Header";
import Footer from "../components/Footer";
import StoresRow from "../components/StoresRow";
import StoreItem from "../components/StoreItem";
import Categories from "../components/Categories";
import Row from "../components/Row";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Banner />
      <Categories />
      <Row title="Top Deals" description="" bgColor="" what="deals" />
      <Row
        title="Most selling Products"
        description=""
        bgColor="#eee"
        what="most"
      />
      <StoresRow title="Top Stores" />
      <StoreItem />
      <Footer />
    </>
  );
};

export default Home;
