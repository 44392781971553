import React, { useEffect, useState } from "react";
import "./components.scss";
import { get_categories } from "../api/endpoint";
import { Link } from "react-router-dom";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    async function get() {
      const data = await get_categories();
      localStorage.setItem("categories", JSON.stringify(data));
      setCategories(data);
    }
    get();
  }, []);

  return (
    <div className="mb-10">
      <div style={{ height: 100 }}></div>
      <div className="category">
        {categories.map((category) => (
          <div className="category__card mb-10">
            <Link to={`/category/${category.id}`} key={category.category_id}>
              <img
                src={`../imgs/${category.images}`}
                className="category__image"
                alt={category.category}
              />
            </Link>
            <p class="category__name">{category.category}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
