import React from "react";

const Banner = () => {
  return (
    <div
      id="carouselExampleCaptions"
      className="carousel slide  carousel-fade"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src="../imgs/slide1.jpg" className="" alt="..." />
          <div className="carousel-caption d-none d-md-block">
            <h2 class="h1">UPRICER</h2>
            <p>
              Search and Compare over 1 million products from Over 3000
              retailers
            </p>
          </div>
        </div>
        <div className="carousel-item">
          <img src="../imgs/slide1.jpg" className="" alt="..." />
          <div className="carousel-caption d-none d-md-block">
            <h2 class="h1">List Your products.</h2>
            <p>Get in face of your customer today.</p>
          </div>
        </div>
        <div className="carousel-item">
          <img src="../imgs/slide1.jpg" className="" alt="..." />
          <div className="carousel-caption d-none d-md-block">
            <h2 class="h1">Get upto 10% off</h2>
            <p>
              Explore Popular Categories, Time is not waiting. It’s a stunning
              chance of saving.
            </p>
            <p>
              <a className="btn btn-lg btn-warning" href="/deals">
                Browse Hot Deals
              </a>
            </p>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Banner;
