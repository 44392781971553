import React, { useEffect, useState } from "react";
import "./components.scss";
import { get_all_stores } from "../api/endpoint";
import StoreItem from "./StoreItem";

const StoresRow = ({ title }) => {
  const [stores, setStores] = useState([]);

  useEffect(() => {
    async function get() {
      const data = await get_all_stores();
      setStores(data);
    }
    get();
  }, []);

  return (
    <div className="StoresRow" style={{}}>
      <h2 className="StoresRow__title  display-5">{title}</h2>
      <div class="row mb-2 mt-10" style={{ padding: 0 }}>
        {stores.slice(0, 4).map((store) => (
          <StoreItem store={store} key={store.store_id} size={"col-md-3"} />
        ))}
      </div>
    </div>
  );
};

export default StoresRow;
