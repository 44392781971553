import React from "react";
import { Link } from "react-router-dom";
import { image_url } from "../environment";

const StoreListItem = ({ store }) => {
  return (
    <div className="d-flex bd-highlight storeCardItem">
      <div className="p-2 flex-fill bd-highlight">
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <img
              src={`${image_url}${store.logo}`}
              alt={store.store_name}
              className="store_logo"
              onError={(e) => (e.target.src = "../imgs/shop.png")}
              style={{ marginRight: 15 }}
            />
          </div>
          <div class="flex-grow-1 ms-3">
            <b>{store.store_name}</b>
            <br />
            <small>{store.address_line}</small>
          </div>
        </div>
      </div>
      <div className="p-2 flex-fill bd-highlight">
        <b style={{ color: "#444" }}>
          Ush {parseInt(store.price).toLocaleString("en")}
        </b>
        <br />
        <small>Price</small>
      </div>
      <div className="ms-auto p-2 flex-fill bd-highlight">
        <Link
          to={`/store/${store.store_id}`}
          style={{ textDecoration: "none", fontSize: 12 }}
        >
          Visit {store.store_name}
        </Link>
        <br />
        <small>Go to page</small>
      </div>
    </div>
  );
};

export default StoreListItem;
