import React from "react";
import { image_url } from "../environment";
import { Link } from "react-router-dom";

const CompareItem = ({ product }) => {
  const renderFeatures = (value) => {
    const keys = Object.keys(value)[0];
    return (
      <li className="list-group-item">
        {value[keys]}
        <small style={{ color: "#fff" }}>N</small>
      </li>
    );
  };

  return (
    <div className="col-md-3" style={{ padding: 0 }}>
      <div className="card" style={{ width: "100%" }}>
        <img
          src={`${image_url}/${product.images}`}
          className="card-img-top1"
          alt="..."
        />

        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            {product.prdt_name}
            <small style={{ color: "#fff" }}>N</small>
          </li>
          <li className="list-group-item">
            Ush .{product.price} <small style={{ color: "#fff" }}>N</small>
          </li>
          <li className="list-group-item">
            Ush .{product.discount_price}{" "}
            <small style={{ color: "#fff" }}>N</small>
          </li>
          <li className="list-group-item">
            {product.tags} <small style={{ color: "#fff" }}>N</small>
          </li>
          <li className="list-group-item">
            {product.shipping} <small style={{ color: "#fff" }}>N</small>
          </li>
          <li className="list-group-item">
            {product.stock_count} <small style={{ color: "#fff" }}>N</small>
          </li>
          {product.features.map((value) => renderFeatures(value))}
        </ul>
        <div className="card-body">
          <Link to={`/product/${product.product_id}`} className="card-link">
            View Product
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CompareItem;
