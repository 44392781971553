import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import About from "./Pages/About";
import Auth from "./Pages/Auth";
import Categories from "./Pages/Categories";
import Category from "./Pages/Category";
import Compare from "./Pages/Compare";
import Deals from "./Pages/Deals";
import Home from "./Pages/Home";
import Notfound from "./Pages/Notfound";
import Product from "./Pages/Product";
import Profile from "./Pages/Profile";
import Search from "./Pages/Search";
import Store from "./Pages/Store";
import Stores from "./Pages/Stores";

const Navigation = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/product/:id" component={Product} />
        <Route exact path="/deals" component={Deals} />
        <Route exact path="/stores" component={Stores} />
        <Route exact path="/store/:id" component={Store} />
        <Route exact path="/category/:id" component={Category} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/about" component={About} />
        <Route exact path="/auth" component={Auth} />
        <Route exact path="/compare/:product_id" component={Compare} />
        <Route exact path="/categories" component={Categories} />
        <Route exact path="/search/:query" component={Search} />
        <Route component={Notfound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Navigation;
