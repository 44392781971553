import React, { useState, useEffect } from "react";
import "./components.scss";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Header = () => {
  const [user, setUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const history = useHistory();
  const getUser = () => {
    const data = localStorage.getItem("u_pricer_user");
    const local = data !== null ? JSON.parse(data) : null;
    setUser(local);
  };

  const logout = () => {
    localStorage.clear();
    history.push("Auth");
  };

  const moveToSearchPage = () => {
    history.push(`/search/${searchQuery}`);
  };

  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      <nav className="py-2 sticky-top navbar-dark bg-dark">
        <div className="container d-flex flex-wrap">
          <ul className="nav me-auto">
            <li className="nav-item">
              <Link to="/" className="nav-link link-dark px-2 active">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/deals" className="nav-link link-dark px-2">
                Hot Deals
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/stores" className="nav-link link-dark px-2">
                Big Stores
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/categories" className="nav-link link-dark px-2">
                All Categories
              </Link>
            </li>
          </ul>
          {user ? (
            <ul className="nav">
              <li className="nav-item">
                <Link to="/profile" className="nav-link link-dark px-2">
                  Favorite
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/profile" className="nav-link link-dark px-2">
                  Profile
                </Link>
              </li>
              <li className="nav-item">
                <a
                  href=""
                  className="nav-link link-dark px-2"
                  onClick={() => logout()}
                >
                  Logout
                </a>
              </li>
            </ul>
          ) : (
            <ul className="nav">
              <li className="nav-item">
                <Link to="/auth" className="nav-link link-dark px-2">
                  Login / Register
                </Link>
              </li>
            </ul>
          )}
        </div>
      </nav>
      <header className="py-2" style={{ backgroundColor: "#f1f1f1" }}>
        <div className="container d-flex flex-wrap justify-content-center">
          <Link
            to="/"
            className="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto text-dark text-decoration-none"
          >
            <img
              src="../logo.png"
              style={{ height: 30, width: 30, marginRight: 10 }}
            />
            <span className="fs-4">UPRICER</span>
          </Link>
          <form className="col-12 col-lg-auto">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Search for ..."
                aria-label="Search for ..."
                aria-describedby="button-addon2"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button
                class="btn btn-primary"
                type="button"
                id="button-addon2"
                onClick={() => moveToSearchPage()}
              >
                Search
              </button>
            </div>
          </form>
        </div>
      </header>
    </>
  );
};

export default Header;
