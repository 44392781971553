import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Notfound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <div class="px-4 py-5 my-5 text-center">
        <img
          class="d-block mx-auto mb-4"
          src="../imgs/not-found.png"
          alt=""
          width="72"
          height="57"
        />
        <h1 class="display-5 fw-bold">Oops sorry !!</h1>
        <div class="col-lg-6 mx-auto">
          <small class="lead mb-10">
            Search and Compare over 1 million products from Over 3000 retailers
          </small>
          <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <a href="/" class="btn btn-warning btn-sm px-4 gap-3">
              Back Home
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Notfound;
