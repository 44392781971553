import React, { useEffect, useState } from "react";
import "./components.scss";
import { get_products, url } from "../api/endpoint";
import ProductItem from "./ProductItem";

const Row = ({ title, description, bgColor, what }) => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    async function get() {
      const data = await get_products();
      if (what === "deals") {
        setProducts(data.filter((value) => value.discount_price != "0"));
      } else {
        setProducts(data.filter((value) => value.discount_price == "0"));
      }
    }
    get();
  }, []);
  return (
    <div className="row" style={{ backgroundColor: bgColor }}>
      <h3 className="row__title display-5">{title}</h3>
      <p className="row__description">{description}</p>
      <div className="row__content">
        {products.map((product) => (
          <ProductItem product={product} key={product.product_id} ur={url} />
        ))}
      </div>
    </div>
  );
};

export default Row;
