import React, { useEffect, useState } from "react";
import { get_all_deals } from "../api/endpoint";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ProductItem from "../components/ProductItem";

const Deals = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    async function get() {
      window.scrollTo(0, 0);
      const data = await get_all_deals();
      setProducts(data);
    }
    get();
  }, []);

  return (
    <div>
      <Header />
      <div class="px-4 my-5 text-center">
        <h1 class="display-5 fw-bold">Hottest Deals this week</h1>
        <div class="col-lg-6 mx-auto">
          <p class="lead mb-4">
            UPRICER, The best place for Deals, Discounts &amp; Freebies ♥ Find
            daily great offers and voucher codes ✅ Your Shopping Community.
          </p>
          <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <a href="/auth" class="btn btn-warning btn-lg px-4 gap-3">
              Notify Me
            </a>
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="row mt-20" style={{ padding: 0 }}>
            {products.map((product) => (
              <div className="col-md-4" key={product.product_id}>
                <ProductItem product={product} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Deals;
