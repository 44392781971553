import React, { Component } from "react";
import Header from "../components/Header";

export class About extends Component {
  render() {
    return;
    <div>
      <Header />
    </div>;
  }
}

export default About;
