import React, { useState, useEffect } from "react";
import { get_products_for_Search } from "../api/endpoint";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ProductItem from "../components/ProductItem";

const Search = ({ match }) => {
  const query = match.params.query;
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    async function get() {
      const result = await get_products_for_Search(query);
      console.log(result);
      setSearchResults(result);
    }
    get();
  }, [query]);

  return (
    <div>
      <Header />
      <section className="container">
        <hr />
        <h3>
          All results found for products <b>{query}</b>
        </h3>
        <hr />
        <div className="row mt-20" style={{ padding: 0 }}>
          {searchResults.map((product) => (
            <div className="col-md-3" key={product.product_id}>
              <ProductItem product={product} />
            </div>
          ))}
          {searchResults.length === 0 && (
            <div>
              <h5>No products</h5>
              <p>
                We could not find any results for <b>{query}</b>
              </p>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Search;
