import React from "react";
import { Link } from "react-router-dom";
import "./components.scss";
import { image_url } from "../environment";

const StoreItem = ({ store, size }) => {
  return (
    <>
      {store && (
        <div className={size}>
          <div className="card">
            <div className="card-body">
              <small>
                <i>{store.store.category || "Dealers"}</i>
              </small>
              <h5 className="card-title">{store.store.store_name}</h5>
              <small className="card-text">
                Located at {store.store.address_line} with over{" "}
                <b>{store.products.length}+ </b>Products
              </small>
              <p className="card-text">
                <small className="text-muted">
                  Joined on <b>{store.store.joined}</b>
                </small>
              </p>
              <a
                href={`/store/${
                  store.store.store_id
                }?store=${store.store.store_name
                  .toLowerCase()
                  .replace(" ", "_")}`}
                className="stretched-link"
              >
                Visit store page
              </a>
            </div>
            <img
              src={`${image_url}/${store.store.cover}`}
              alt=""
              className="card-img-bottom"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default StoreItem;
