import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get_compare_data } from "../api/endpoint";
import CompareItem from "../components/CompareItem";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { image_url } from "../environment";
import { Link } from "react-router-dom";
import Row from "../components/Row";

const Compare = () => {
  const { product_id } = useParams();
  const [data, setData] = useState({});
  const [compareList, setCompareList] = useState([]);
  const [features, setFeatures] = useState([
    "product Name",
    "Price",
    "Discount Price",
    "Tags",
    "Shipping",
    "Stock count",
  ]);

  const getCompareData = async () => {
    const result = await get_compare_data(product_id);
    setData(result);
    setCompareList([...compareList, result.product]);
    getFeaturesFromProduct(result);
  };

  const getFeaturesFromProduct = (result) => {
    if (result) {
      const newFeatures = result.product?.features.map(
        (feature) => Object.keys(feature)[0]
      );
      setFeatures([...features, ...newFeatures]);
    }
  };

  const addNewProduct = (prod) => {
    if (compareList.length === 3) return;
    setCompareList([...compareList, prod]);
  };

  useEffect(() => {
    getCompareData();
  }, []);

  return (
    <div>
      <Header />
      <section className="productList">
        <div className="container">
          <h6 className="display-6">Compare products</h6>
          <p>
            Select from product list to compare with{" "}
            {compareList.length > 0 &&
              compareList[0].prdt_name.substring(0, 10)}
          </p>
        </div>
        <div className="container productListInner">
          {data.products &&
            data.products.map((product) => {
              return (
                <a className="listItem" onClick={() => addNewProduct(product)}>
                  <img
                    src={`${image_url}/${product.images}`}
                    alt=""
                    style={{ height: 40, width: 40 }}
                  />
                  <br />
                  <small>{product.prdt_name}</small>
                </a>
              );
            })}
        </div>
      </section>
      <section className="container">
        <div className="row" style={{ padding: 0 }}>
          <div
            className="col-md-3"
            style={{ padding: 0, backgroundColor: "#f1f1f1" }}
          >
            <div
              className="card"
              style={{ width: "100%", backgroundColor: "#f1f1f1" }}
            >
              <img
                src="../imgs/brand-.png"
                className="card-img-top1"
                alt="..."
              />
              <ul className="list-group list-group-flush">
                {features.map((val) => {
                  return (
                    <li
                      className="list-group-item"
                      style={{ backgroundColor: "#f1f1f1" }}
                    >
                      <b className="itemKey">{val}</b>
                    </li>
                  );
                })}
              </ul>
              <div className="card-body">View Product</div>
            </div>
          </div>
          {compareList.map((value) => (
            <CompareItem key={value.product_id} product={value} />
          ))}
        </div>
      </section>
      <Row
        title="Most selling Products"
        description=""
        bgColor="#eee"
        what="most"
      />
      <Footer />
    </div>
  );
};

export default Compare;
