import React, { useState } from "react";
import { login, register } from "../api/endpoint";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Auth = ({ history }) => {
  const [activeForm, setActiveForm] = useState("login");
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const changeActiveForm = (form) => {
    setActiveForm(form);
  };

  const loginUser = () => {
    const data = { email, password };
    setProcessing(true);
    setErrorMessage(null);
    login(data)
      .then((res) => {
        setProcessing(false);
        setErrorMessage(null);
        if (res.flag) {
          localStorage.setItem("u_pricer_user", JSON.stringify(res.user));
          history.push("/");
        } else {
          setErrorMessage(res.msg);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setProcessing(false);
      });
  };

  const signUp = () => {
    setProcessing(true);
    setErrorMessage(null);
    const data = {
      email,
      password,
      name: `${firstName} ${lastName}`,
      role: "user",
      date_joined: new Date(),
    };
    register(data)
      .then((res) => {
        setProcessing(false);
        setErrorMessage(null);
        if (res.flag) {
          localStorage.setItem("u_pricer_user", JSON.stringify(res.user));
          history.push("/");
        } else {
          setErrorMessage(res.msg);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setProcessing(false);
      });
  };

  return (
    <div>
      <Header />
      <main className="form-signin text-center py-5">
        {activeForm === "login" ? (
          <div className="py-5">
            <form>
              <h1 className="h3 mb-3 fw-normal">UPRICER</h1>
              <p>Login and get the latests offers</p>

              <div className="form-floating">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  onChange={(text) => setEmail(text.target.value)}
                />
                <label for="floatingInput">Email address</label>
              </div>
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  onChange={(text) => setPassword(text.target.value)}
                />
                <label for="floatingPassword">Password</label>
              </div>
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </form>
            <button
              className="w-100 btn btn-lg btn-primary"
              type="submit"
              onClick={(e) => loginUser()}
              disabled={processing}
            >
              Sign in
            </button>
            <button
              className="w-100 btn btn-lg btn-light mt-5"
              onClick={() => changeActiveForm("signup")}
            >
              Creat Account
            </button>
            <small className="mt-5 mb-3 text-muted">
              UPRICER &copy; 2021–Present
            </small>
          </div>
        ) : (
          <div className="py-5">
            <form>
              <h1 className="h3 mb-3 fw-normal">UPRICER</h1>
              <p>Create your Upricer Account today</p>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  onChange={(text) => setFirstName(text.target.value)}
                />
                <label for="floatingInput">First Name</label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  onChange={(text) => setLastName(text.target.value)}
                />
                <label for="floatingInput">Last Name</label>
              </div>
              <div className="form-floating">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  onChange={(text) => setEmail(text.target.value)}
                />
                <label for="floatingInput">Email address</label>
              </div>
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  onChange={(text) => setPassword(text.target.value)}
                />
                <label for="floatingPassword">Password</label>
              </div>
              {errorMessage && (
                <small style={{ color: "red" }}>{errorMessage}</small>
              )}
            </form>
            <button
              className="w-100 btn btn-lg btn-primary"
              type="submit"
              onClick={(e) => signUp()}
              disabled={processing}
            >
              Sign Up
            </button>
            <button
              className="w-100 btn btn-lg btn-light mt-5"
              onClick={() => changeActiveForm("login")}
            >
              Login
            </button>

            <p className="mt-5 mb-3 text-muted">UPRICER &copy; 2021–Present</p>
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default Auth;
