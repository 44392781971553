import React from "react";
import { Link } from "react-router-dom";
import { image_url } from "../environment";

const ProductItem = ({ product }) => {
  const getSalePercentage = () => {
    if (product.discount_price === "0") return;

    const percentage = Math.floor(
      ((parseInt(product.discount_price, 10) - parseInt(product.price, 10)) *
        100) /
        parseInt(product.price, 10)
    );
    console.log(percentage);

    return (
      <>
        {percentage !== 0 && (
          <span
            style={{
              padding: 5,
              backgroundColor: "orangered",
              color: "white",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 100,
              width: 50,
              textAlign: "center",
              fontSize: 12,
              borderTopLeftRadius: 10,
              fontWeight: "bold",
            }}
          >
            {percentage}%
          </span>
        )}
      </>
    );
  };
  return (
    <div>
      <div className="ProductCard">
        {getSalePercentage()}
        <Link to={`/product/${product.product_id}`}>
          <div
            style={{
              textAlign: "center",
              borderBlockWidth: 1,
              borderBottomColor: "#eee",
            }}
          >
            <img
              src={`${image_url}/${product.images}`}
              alt=""
              className="ProductCard__image mb-10"
            />
          </div>
        </Link>
        <p className="ProductCard__name">
          {product.prdt_name.length > 20
            ? `${product.prdt_name.slice(0, 20)}...`
            : product.prdt_name}
        </p>
        <div className="ProductCard__footer">
          <p className="ProductCard__cash">
            <small
              style={{
                textDecoration:
                  product.discount_price !== "0" ? "line-through" : "",
              }}
            >
              Ush {parseInt(product.price).toLocaleString("en")}
            </small>
            {product.discount_price !== "0" && (
              <>
                <small style={{ color: "orangered" }}>
                  {"   "}
                  Ush {parseInt(product.discount_price).toLocaleString("en")}
                </small>
              </>
            )}
          </p>
          <a href="#" className="ProductCard__btn">
            <small>{product.views} views</small>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
